.plans-compare-table-basic {
  margin-bottom: 30px;
}

.manage-hosting-wrap {
  padding-top: 10px;
  padding-bottom: 45px;
}

.manage-hosting-wrap .manage-hosting-content .sect-heading {
  margin: 0 auto 35px;
  font-size: 34px;
  font-weight: 700;
  line-height: 46px;
  text-align: center;
}

.manage-hosting-wrap .manage-hosting-content .sect-heading .highlighted {
  color: #2a862e;
}

@media (max-width: 767.98px) {
  .manage-hosting-wrap .manage-hosting-content .sect-heading {
    font-size: 26px;
    line-height: 32px;
  }
}

.manage-hosting-wrap .manage-hosting-content .box2 {
  padding: 10px 25px;
  display: flex;
  flex-direction: column;
}

.manage-hosting-wrap .manage-hosting-content .box2 .box-img {
  width: 85%;
  max-width: 400px;
  height: auto;
  margin: 0 auto 10px;
  max-height: 270px;
  object-fit: contain;
}

.manage-hosting-wrap .manage-hosting-content .box2 .box-heading {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 15px;
}

.manage-hosting-wrap .manage-hosting-content .box2 .box-description {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 20px;
}

.manage-hosting-wrap .manage-hosting-content .box2 .box-link {
  font-weight: 700;
  color: #2a862e;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.manage-hosting-wrap .manage-hosting-content .box2 .box-link:after {
  content: "";
  width: 18px;
  height: 9.8px;
  display: inline-block;
  margin-left: 6px;
  background-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 283.5 154.3"> <path fill="%2331313c" d="M198.5,83.6H10.6c-3.8,0-6.9-3.1-6.9-6.9s3.1-6.9,6.9-6.9h187.9c3.8,0,6.9,3.1,6.9,6.9S202.3,83.6,198.5,83.6z" /> <path fill="%2331313c" d="M208.5,147c-1.8,0-3.5-0.7-4.9-2c-2.7-2.7-2.7-7.1,0-9.8l58.5-58.5l-58.5-58.5c-2.7-2.7-2.7-7.1,0-9.8 c2.7-2.7,7.1-2.7,9.8,0l63.4,63.4c1.3,1.3,2,3,2,4.9c0,1.8-0.7,3.6-2,4.9L213.4,145C212,146.4,210.2,147,208.5,147z"/></svg> ') !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: 100% !important;
}

@media (max-width: 1199.98px) {
  .manage-hosting-wrap .manage-hosting-content .box2 .box-img {
    width: 100%;
  }
}

@media (max-width: 991.98px) {
  .manage-hosting-wrap .manage-hosting-content .box2 {
    padding: 35px 40px;
  }
}

@media (max-width: 767.98px) {
  .manage-hosting-wrap .manage-hosting-content .box2 {
    padding: 0px;
  }
}
.row.manage-hosting-content {
  margin: 35px auto 0;
  padding: 35px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 28px 70px 0 rgba(220, 223, 230, 0.38);
}
