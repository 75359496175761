.heading {
  background: #000;
  color: #fff;
  width: fit-content;
  margin: auto;
  padding: 25px;
  border-radius: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
}
