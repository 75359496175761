.popupbutton {
  color: #fff;
  box-shadow: 0 0 40px 40px #008000 inset, 0 0 0 0 #087e08;
  transition: all 150ms ease-in-out;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border-radius: 0.6em;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-self: center;
  font-size: 1rem;
  line-height: 1;
  margin: 20px auto;
  padding: 1.2em 2em;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  border: unset;
}
