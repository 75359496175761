@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    font-size: 12px;
    left: auto;
    width: 75vw;
    top: 5px;
    right: 10px;
  }
}
@media only screen and (max-width: 480px) {
  .Toastify__toast {
    min-height: 50px;
  }
}
body {
  background-color: transparent !important;
}
