.team_section {
  color: #000;
  text-align: center;
  background-image: linear-gradient(95deg, #846cff 0%, #7660e4 100%);
  padding-top: 20px;
  padding-bottom: 30px;
}
.team_section > div {
  background-image: url(https://capitalise.ai/wp-content/uploads/2021/07/Quote-1.svg);
  background-position: 88% -100px;
  background-repeat: no-repeat;
  background-size: auto;
  opacity: 1;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.team_section .heading_container {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.team_section .team_container {
  padding: 0 15px;
}

.team_section .team_container .box {
  padding: 35px 0 0 0;
  border-radius: 5px;
  overflow: hidden;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
  margin-top: 45px;

  background: #fff;
}

.team_section .team_container .box .img-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.team_section .team_container .box .img-box img {
  width: 120px;
  border-radius: 100%;
  border: 5px solid #f3f9ff;
}

.team_section .team_container .box .detail-box {
  margin: 25px 0;
}

.team_section .team_container .box .detail-box h5 {
  color: #000;
  font-weight: 600;
  position: relative;
}

.team_section .team_container .box .social_box {
  padding: 10px 45px 20px 45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.team_section .team_container .box .social_box a {
  color: #43a048;
  font-size: 22px;
}

.team_section .team_container .box .social_box a:hover {
  color: #000;
}
