.dashboard_layout {
  display: grid;
  grid-template-columns: 275px 1fr;
  grid-template-rows: 100px 1fr;
}

.dashboard_layout div:nth-child(1) {
  grid-area: 1 / 1 / 3 / 2;
}
.dashboard_layout div:nth-child(2) {
  grid-area: 1 / 2 / 2 / 3;
}
.dashboard_layout div:nth-child(3) {
  grid-area: 2 / 2 / 3 / 3;
}
.Main_component {
  padding: 10px;
}
@media (max-width: 768px) {
  .dashboard_layout {
    display: block;
  }
}
