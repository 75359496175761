.widget.widget_mc4wp_form_widget {
  min-height: 430px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.widget.widget_mc4wp_form_widget.active {
  background-color: unset !important;
  color: #fff;
}
