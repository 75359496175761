.table-header {
  background-color: #28a745;
  color: white;
}
.tableline {
  border-right: 1px solid #c6c7c8;
}
th {
  cursor: pointer !important;
  position: relative;
  color: #1e7e34 !important;
}
td {
  white-space: nowrap; /* Prevents text wrapping */
}

td {
  overflow: hidden; /* Hides overflowing text */
  text-overflow: ellipsis; /* Adds "..." for overflow text */
}
tr {
  vertical-align: middle;
}
.table-bordered tr > th {
  text-align: center;
}
.table-bordered tr > th > i {
  margin-left: 5px;
}
.table-bordered tr > th:nth-child(1),
.table-bordered tr > td:nth-child(1) {
  width: 14%;
}
.table-bordered tr > th:nth-child(2),
.table-bordered tr > td:nth-child(2) {
  width: 14%;
}
.table-bordered tr > th:nth-child(3),
.table-bordered tr > td:nth-child(3) {
  width: 14%;
}
.table-bordered tr > th:nth-child(4),
.table-bordered tr > td:nth-child(4) {
  width: 14%;
}
.table-bordered tr > th:nth-child(5),
.table-bordered tr > td:nth-child(5) {
  width: 18%;
}
.table-bordered tr > th:nth-child(6),
.table-bordered tr > td:nth-child(6) {
  width: 14%;
}
.table-bordered tr > th:nth-child(7),
.table-bordered tr > td:nth-child(7) {
  width: 12%;
}
.FilterRow > div:last-child {
  display: flex !important;
  align-items: end;
}
.exportbtn {
  text-align: end;
}
@media (max-width: 768px) {
  .tableoverflow {
    overflow-x: auto;
    white-space: nowrap;
  }
  .FilterRow {
    flex-wrap: wrap;
  }
  .FilterRow > div {
    flex: unset;
    width: 100%;
    margin-bottom: 10px;
  }
  .FilterRow > div:last-child {
    margin-right: 1rem;
    justify-content: center;
  }
  tbody tr:hover {
    transform: scale(1) !important;
  }
}
.FilterRowflex {
  flex: unset !important;
}
.remove_button {
  background-color: #000 !important;
  border: unset;
}
