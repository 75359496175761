#testimonials {
  color: #212529;
  background-color: #f3f9ff;
  background-color: rgb(241 245 249 / 1);

  padding: 60px 0;
  scroll-margin-top: 90px;
  overflow: clip;
  margin-top: 40px;
}

.testimonials .testimonial-item {
  background-color: #ffffff;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.08);
  padding: 30px;
  position: relative;
  height: 100%;
  transition: all ease-in-out 0.3s;
  border-radius: 20px;
}
.testimonials .testimonial-item:hover {
  box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
  transform: translateY(-10px);
}

.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 50px;
  border: 6px solid #f3f9ff;
  float: left;
  margin: 0 10px 0 0;
}

.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: color-mix(in srgb, #212529, transparent 20%);
  margin: 0;
}

.testimonials .testimonial-item .stars {
  margin: 10px 0;
}

.testimonials .testimonial-item .stars i {
  color: #43a048;
  margin: 0 1px;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: #43a048;
  font-size: 15px;
  line-height: 0;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 15px 0 0 0;
  padding: 0;
}
