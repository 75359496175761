.keyfeatures {
  margin: 30px 0;
}
h2.sect-heading {
  font-weight: bold;
}
.keysvgg {
  display: flex;
  justify-content: center;
}
.keysvg {
  background-color: #edf6ed;
  display: inline-block;
  padding: 30px;
  border-radius: 50%;
  margin: 15px 0px;
}
.keysvg svg#Layer_1 {
  width: 45px;
  fill: #43a048;
}
.keyfeaturessec1 {
  display: flex;
  align-items: center;
  grid-gap: 20px;
}
@media (max-width: 768px) {
  .keyfeaturessec1 {
    flex-direction: column;
  }
}
