.Home_support__SOyyM {
  position: relative;
  background-color: #121319;
}

.Home_support__SOyyM .Home_width_wrapper__GSPRv {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
  opacity: 0;
}

.Home_support__SOyyM .Home_bg__iD3nR {
  position: absolute;
}

.Home_support__SOyyM .Home_bg_grid__ecfG7 {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.Home_support__SOyyM .Home_bg_grid_mob__OZHlF {
  display: none;
}

.Home_support_cs__LXLe8 {
  flex: 2;
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: #514ed8;
  border-radius: 12px;
  color: #fff;
  margin: 20px 20px;
  position: relative;
}

.Home_support_cs_title___FEhH {
  font-size: 32px;
  line-height: 44px;
  font-weight: 800;
  margin: 0;
}

.Home_support_cs_smalltext__IQt5t {
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  margin: 24px 0 0;
}

.Home_support_cs_text__5X2T_ {
  font-size: 24px;
  line-height: 32px;
  font-weight: 800;
  margin: 0;
}

.Home_support_cs_img__SHNSl {
  margin-top: 40px;
}

.Home_support_faq__RVsNP {
  flex: 3;
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: #1b1e2d;
  border-radius: 12px;
  color: #fff;
  margin: 20px 20px;
}

.Home_support_faq_title__bzoAP {
  font-size: 32px;
  line-height: 44px;
  font-weight: 800;
  margin: 0;
}

.Home_support_faq_list__Vk1CE {
  display: flex;
  flex-direction: column;
}

.Home_support_faq_list_item__ffIcl {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 20px 0 0;
  border-bottom: 1px solid #323542;
  cursor: pointer;
}

.Home_support_faq_list_item_question__6jWSk {
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 6px 0 10px;
}

.Home_support_faq_list_item_answer__T3TCM {
  transition: 0.3s ease-in;
  max-height: 0;
  overflow: scroll;
  margin: 0 0 16px;
  color: #c3c3c5;
}

.Home_support_faq_list_item_open__jOKSK
  .Home_support_faq_list_item_answer__T3TCM {
  max-height: 100px;
}

.Home_support_faq_list_item_icon__pfz4S {
  transition: 0.3s linear;
  transform-origin: center;
}

.Home_support_faq_list_item_icon_open__YjFo0 {
  transform: rotate(180deg);
}

.Home_support_faq_list_item__ffIcl:last-of-type {
  border-bottom: 0;
}
.Home_width_wrapper__GSPRv.Home_fadeIn__VnhJK a {
  color: #fff;
  text-decoration: none;
}
