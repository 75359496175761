/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.header {
  transition: all 0.5s;
  z-index: 997;
  padding: 15px 0;
  background: #040515;
  /*background: #fff;*/
  /*box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);*/
}

.header.header-scrolled {
  background: #040515;
  padding: 15px 0;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
}

.header .logo {
  line-height: 0;
}

.header .logo img {
  max-height: 60px;
  margin-right: 6px;
}

.header .logo span {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #012970;
  font-family: "Nunito", sans-serif;
  margin-top: 3px;
}
.mobile-nav-toggle {
  display: none;
}

/*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
/**
  * Desktop Navigation 
  */
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-size: 16px;
  font-weight: 100;
  color: #fff;
  white-space: nowrap;
  transition: 0.3s;
  font-weight: 700;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

#navbar a:hover,
#navbar .active,
#navbar .active:focus,
#navbar li:hover > a {
  color: #008000;
}

.navbar .getstarted,
.navbar .getstarted:focus {
  background: #008000;
  padding: 8px 20px;
  margin-left: 30px;
  border-radius: 4px;
  color: #fff;
}

.navbar .getstarted:hover,
.navbar .getstarted:focus:hover {
  color: #fff;
  background: #5969f3;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 15px;
  text-transform: none;
  font-weight: 600;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
  color: #d20202;
}

.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .megamenu {
  position: static;
}

.navbar .megamenu ul {
  margin-top: 5px;
  right: 0;
  padding: 10px;
  display: flex;
}

.navbar .megamenu ul li {
  flex: 1;
}

.navbar .megamenu ul li a,
.navbar .megamenu ul li:hover > a {
  color: #013289;
}

.navbar .megamenu ul li a:hover,
.navbar .megamenu ul li .active,
.navbar .megamenu ul li .active:hover {
  color: #d20202;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}
@media (max-width: 768px) {
  .navbar ul {
    display: none;
  }
  #navbar .fa {
    font-size: 30px;
    color: #fff;
  }
  .navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    /* background: rgba(1, 22, 61, 0.9); */
    /* background: rgb(210 2 2 / 66%); */
    background: rgb(0 0 0 / 80%);
    transition: 0.3s;
    /* height: 195px; */
    z-index: 9;
  }
  .navbar-mobile ul {
    display: block;
    position: absolute;
    top: 100px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    border-radius: 10px;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
    height: fit-content;
  }
  .navbar-mobile a,
  .navbar-mobile a:focus {
    padding: 10px 20px;
    font-size: 15px;
    color: #000;
  }
  #navbar .fa-close {
    position: absolute;
    top: 30px;
    right: 15px;
  }
}
@media (min-width: 769px) {
  #navbar i.fa.fa-bars {
    display: none;
  }
}
